export default [ 
    'Mandarim',
    'Espanhol',
    'Inglês',
    'Português',
    'Hindi',
    'Árabe',
    'Bengali',
    'Russo',
    'Japonês',
    'Lahnda',
    'Javanês',
    'Coreano',
    'Alemão',
    'Francês',
    'Telugo',
    'Marati',
    'Turco',
    'Urdu',
    'Vietnamita',
    'Tâmil',
    'Italiano'
].sort();